import styled from 'styled-components';

export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  LIGHT_BLACK: 'rgba(0, 0, 0, 0.33)',
  MEDIUM_BLACK: 'rgba(0, 0, 0, 0.85)',
  GRAY: '#ebebeb',
  BLUE: '#00467d',
  LIGHT_GREY: '#f5f5f5',
  LIGHT_BLUE: '#c1dce4',
  RED: '#FF0000',
  OPAQUE_RED: '#FFEEEE',
  DARK_GREY: '#232425',
  LIGHT_DARK_GREY: '#5b5d62',
  LIGHT_YELLOW: '#f7efda',
  OPAQUE_BLACK: '#1e1e1e',
  GREY_V2: '#787878',
  GREY_V3: '#f1f1f0',
  GREY_V4: '#7a7a7a',
  GREY_V5: '#979797',
  GREY_V6: 'rgba(216, 216, 216, 0.3)',
  ALTO_GREY: '#d8d8d8',
  DOVE_GREY: '#737373',
  DUSTY_GREY: '#949494',
  MERCURY_GREY: '#f0f0ee',
  AZURE_RADIANCE: '#00a1ff',
  BIZARRE_PINK: '#eee0d8',
  HERO_GRADIENT: 'linear-gradient(to left bottom, #fd7135, #ef5f44)',
};

export const MEDIA_QUERY_MIN_RESOLUTIONS = {
  MOBILE: 0,
  TABLET: 768,
  DESKTOP: 1024,
  EXTRA_TABLET: 1277,
  BIG_DESKTOP: 1400,
};

export const CONTAINERS = {
  DESKTOP: '992px',
};

export const MEDIA_QUERY = {
  EXTRA_SMALL_MOBILE: `@media (max-width: 420px) and (max-height: 600px)`,
  SMALL_MOBILE: `@media (max-width: 768px) and (max-height: 750px)`,
  MOBILE: `@media  (max-width: 767px)`,
  MOBILE_AND_SMALL_TABLET: `@media (max-width: 768px)`,
  MOBILE_AND_TABLET: `@media (max-width: 1024px)`,
  TABLET: `@media (min-width: 768px) and (max-width: 1023px)`,
  BIG_TABLET: `@media (min-width: 768px) and (max-width: 1024px)`,
  TABLET_AND_MOBILE: `@media (max-width: 1023px)`,
  TABLET_AND_DESKTOP: `@media (min-width: 768px)`,
  DESKTOP: `@media (min-width: 1024px)`,
  LAPTOP: '@media (min-width: 1024px)',
  SMALL_DESKTOP: '@media (min-width: 1440px)',
  MEDIUM_DESKTOP: '@media (min-width: 1920px)',
  BIG_DESKTOP: '@media (min-width: 2560px)',
  HUGE_DESKTOP: '@media (min-width: 3840px)',
  // Non-traditional viewports
  SMALL_TABLET_PORTRAIT: '@media (width: 1024px) and (height: 768px)',
  TINY_LAPTOP: '@media (width: 1280px) and (height: 720px)',
  SMALL_LAPTOP: '@media (width: 1280px) and (height: 800px)',
  MEDIUM_LAPTOP: '@media (width: 1280px) and (height: 1024px)',
};

export const LANDING_PAGE_MEDIA_QUERY = {
  TABLET: `@media (max-width: 1277px)`,
};

interface H3Props {
  margin?: string;
}

export const H3 = styled.h3<H3Props>(props => ({
  width: '100%',
  fontFamily: 'Arial',
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '1.25',
  margin: props.margin !== undefined ? props.margin : '0',
  color: '#000000',
  display: 'inline',
  [MEDIA_QUERY.TABLET]: {
    fontSize: '36px',
    lineHeight: '1.33',
  },
  [MEDIA_QUERY.DESKTOP]: {
    fontSize: '36px',
    lineHeight: '1.33',
    textAlign: 'left',
    display: 'block',
  },
}));

export const H4 = styled.h4`
  width: 100%;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  margin: 0;
  letter-spacing: normal;
  color: #000000;
  display: inline;
`;

export const KokoTypes = {
  lime: 'lime',
  blueberry: 'blueberry',
  cantaloupe: 'cantaloupe',
};

export const KokoCircles = styled.div`
  display: inline-block;
  float: right;
  margin-right: 25px;
`;

const NumberCircleBase = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: white;
  display: inline-block;
  margin-left: 6px;
`;

export const BlueNumberCircle = styled(NumberCircleBase)`
  background-color: #8791ca;
`;

export const OrangeNumberCircle = styled(NumberCircleBase)`
  background-color: #daa593;
`;

export const GreenNumberCircle = styled(NumberCircleBase)`
  background-color: #a1c28d;
`;

export const EaseInOutTransition = (key: string) => `${key} 0.3s ease-in-out`;
