import React from 'react';
import styled from 'styled-components';

import { MEDIA_QUERY } from '../../commonStyles';

import './hamburgers.css';

const Hamburger = styled.button({
  outline: 'none',
  position: 'fixed',
  top: '31px',
  left: '40px',
  zIndex: 999,
  [MEDIA_QUERY.MOBILE]: {
    top: '27px',
    left: '20px',
  },
  [MEDIA_QUERY.TABLET]: {
    left: '42px',
  },
});

export interface BurgerProps {
  toggleMenu: () => void;
  isActive: boolean;
  className?: string;
}

export const BurgerRenderer: React.FC<JSX.IntrinsicAttributes &
  BurgerProps> = ({ toggleMenu, isActive }: BurgerProps) => {
  return (
    <Hamburger
      className={[
        'hamburger',
        'hamburger--spring',
        isActive && 'is-active',
      ].join(' ')}
      onClick={toggleMenu}
    >
      <div className="hamburger-box">
        <div className="hamburger-inner"></div>
      </div>
    </Hamburger>
  );
};
