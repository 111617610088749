/* eslint-disable react/display-name */
import React, { useState } from 'react';
import styled from 'styled-components';
import { getLink } from '../../constants/links';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';
import { Logo } from './logo';
import Menu from './menu';
import { Cart } from './shoping-cart';
import { BurgerRenderer } from './burger-renderer';

export interface HeaderProps {
  LeftRenderer: React.FC;
  language: string;
  showCart?: boolean;
  dropShadow?: boolean;
  showHamburger?: boolean;
}

interface ContainerProps {
  dropShadow?: boolean;
  menuVisible?: boolean;
}

const Container = styled.div<ContainerProps>(props => ({
  backgroundColor: COLORS.WHITE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px 40px',
  overflowY: 'auto',
  boxShadow: props.dropShadow ? '0 0 36px 0 rgba(0, 0, 0, 0.08)' : 'none',
  [MEDIA_QUERY.TABLET]: {
    padding: '20px 42px',
  },
  [MEDIA_QUERY.MOBILE]: {
    boxShadow: props.menuVisible ? 'none' : '0 0 36px 0 rgba(0, 0, 0, 0.08)',
    padding: '16px 15px',
    zIndex: 998,
  },
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 97,
}));

const Centerer = styled.div({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '44px',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const LeftContainer = styled.div`
  flex: 1 33%;
  text-align: left;
  z-index: 999;
  ${MEDIA_QUERY.MOBILE_AND_SMALL_TABLET} {
    position: absolute;
  }
`;

const Header: React.FC<HeaderProps> = ({
  LeftRenderer,
  language,
  showCart,
  dropShadow,
  showHamburger = true,
}) => {
  const [menuVisible, toggleMenu] = useState(false);
  const goBackArrow = !showCart ? (
    <LeftRenderer language={language} />
  ) : (
    <div />
  );

  return (
    <>
      {showHamburger && (
        <BurgerRenderer
          toggleMenu={() => toggleMenu(!menuVisible)}
          isActive={menuVisible}
        />
      )}
      <Container dropShadow={dropShadow} menuVisible={menuVisible}>
        <Centerer>
          <LeftContainer>{goBackArrow}</LeftContainer>
          <Logo linkUrl={getLink(language, 'homepage')} />
          <Cart language={language} show={!!showCart} />
        </Centerer>
      </Container>
      <Menu
        language={language}
        visible={menuVisible}
        hideMenu={() => toggleMenu(false)}
      />
    </>
  );
};

export default Header;
