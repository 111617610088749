import React from 'react';

export default () => (
  <svg width="34px" height="36px" viewBox="0 0 34 36" version="1.1">
    <title>UI / Navigation / Chevron white forward Copy</title>
    <defs>
      <filter
        x="-37.5%"
        y="-37.5%"
        width="175.0%"
        height="175.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.329411765   0 0 0 0 0.329411765   0 0 0 0 0.329411765  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <circle id="path-2" cx="16" cy="14.9333333" r="13.8666667"></circle>
      <filter
        x="-25.2%"
        y="-18.0%"
        width="150.5%"
        height="150.5%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Artboards"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Shopping-Cart-upsell---Tablet"
        transform="translate(0.000000, -1018.000000)"
      >
        <g
          id="UI-/-Navigation-/-Chevron-white-forward-Copy"
          transform="translate(0.000000, 1019.000000)"
        >
          <g
            id="Group-4"
            filter="url(#filter-1)"
            transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) "
          >
            <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
          </g>
          <g id="Oval">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-3)"
              xlinkHref="#path-2"
            ></use>
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-2"></use>
          </g>
          <path
            d="M10.9419355,6.67286822 C10.7767742,6.50542636 10.7217204,6.3379845 10.7217204,6.11472868 C10.7217204,5.89147287 10.7767742,5.72403101 10.9419355,5.55658915 C11.1070968,5.38914729 11.2722581,5.33333333 11.4924731,5.33333333 C11.7126882,5.33333333 11.8778495,5.38914729 12.0430108,5.55658915 L20.6864516,14.3751938 C20.8516129,14.5426357 20.9066667,14.7100775 20.9066667,14.9333333 C20.9066667,15.1565891 20.8516129,15.324031 20.6864516,15.4914729 L11.987957,24.3100775 C11.8227957,24.4775194 11.6576344,24.5333333 11.4374194,24.5333333 C11.2172043,24.5333333 11.052043,24.4775194 10.8868817,24.3100775 C10.7217204,24.1426357 10.6666667,23.9751938 10.6666667,23.751938 C10.6666667,23.5286822 10.7217204,23.3612403 10.8868817,23.1937984 L18.7595699,14.9333333 L10.9419355,6.67286822 Z"
            id="Path"
            fill="#333333"
            fillRule="nonzero"
            transform="translate(15.786667, 14.933333) scale(-1, 1) translate(-15.786667, -14.933333) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
