import React, { useEffect, useState } from 'react';
import {
  getFromSessionStorage,
  setToSessionStorage,
} from '../../storage/session-storage';
import Banner from '../banner';
import { i18n } from '../../translations';
import { graphql, useStaticQuery } from 'gatsby';

export interface BannerProps {
  language: string;
}

type EndOfServiceData = {
  enableShopping: boolean;
  disableShoppingServicesText: {
    disableShoppingServicesText: string;
  };
  hideable: boolean;
};

const useEndOfService = (language: string): EndOfServiceData => {
  const { allContentfulMenu } = useStaticQuery(
    graphql`
      query EndOfServiceTextQuery {
        allContentfulMenu {
          nodes {
            disableShoppingServicesText {
              disableShoppingServicesText
            }
            enableShopping
            hideable
            node_locale
          }
        }
      }
    `
  );

  return allContentfulMenu.nodes.find(n => n.node_locale === language);
};

const EndOfServiceBanner: React.FC<BannerProps> = ({ language }) => {
  const key = 'endOfServiceInformed';
  const __ = i18n(language);
  const {
    disableShoppingServicesText: text,
    enableShopping,
    hideable,
  } = useEndOfService(language);

  const isInformed = () => getFromSessionStorage(key) === 'true';
  const [show, setShow] = useState(false);

  // It's needed, so the state is not cached during hydration process and the state updates work fine on production build
  useEffect(() => {
    setShow(!enableShopping && !isInformed());
  }, []);

  const setInformed = () => {
    if (setToSessionStorage(key, 'true')) {
      setShow(false);
    }
  };

  return (
    <Banner
      show={show}
      hideable={hideable}
      confirmBtnLabel={__('endOfService.btnLabel')}
      confirmBtnClicked={setInformed}
    >
      {text.disableShoppingServicesText}
    </Banner>
  );
};

export default EndOfServiceBanner;
