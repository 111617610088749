import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';

interface FooterBoxProps {
  to: string;
  title: string;
  isBasketButton: boolean;
}

export class StickyFooter extends Component<FooterBoxProps, {}> {
  public constructor(props) {
    super(props);

    this.scrollTimeout = null;

    this.state = {
      visible: false,
    };

    this.onScrollDebounced = this.onScrollDebounced.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('scroll', this.onScrollDebounced, false);
    window.addEventListener('resize', this.onResize, false);
    this.onScroll();
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.onScrollDebounced, false);
    window.removeEventListener('resize', this.onResize, false);
    window.cancelAnimationFrame(this.scrollTimeout);
  }

  public onResize() {
    this.onScroll();
  }

  public onScrollDebounced() {
    if (this.scrollTimeout) {
      window.cancelAnimationFrame(this.scrollTimeout);
    }

    this.scrollTimeout = window.requestAnimationFrame(this.onScroll);
  }

  public onScroll() {
    const windowHeight = window.innerHeight;
    const scrollTop =
      typeof window !== 'undefined'
        ? window.pageYOffset || document.documentElement.scrollTop
        : 0;

    const visible = scrollTop > windowHeight;

    this.setState({
      visible,
    });
  }

  public render() {
    const {
      to,
      title,
      isBasketButton = false,
    } = this.props;

    return (
      <FooterComponent visible={this.state.visible || isBasketButton}>
        {isBasketButton ? (
          <BlueLink to={to}>{title}</BlueLink>
        ) : (
          <TransparentLink to={to}>{title}</TransparentLink>
        )}
      </FooterComponent>
    );
  }
}

const FooterComponent = styled.div<{ visible: boolean }>(
  {
    backgroundColor: COLORS.LIGHT_BLUE,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.2)',
    zIndex: 90,
    color: 'white',
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.5s ease',
  },
  ({ visible }) => ({
    transform: visible ? 'translate(0, 0)' : 'translate(0, 100%)',
  })
);

const TransparentLink = styled(Link)({
  color: COLORS.BLACK,
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '20px',
  textDecoration: 'none',
  textTransform: 'uppercase',
  border: 'solid 2px #000000',
  padding: '12px 190px',
  '&:hover': {
    opacity: 0.64,
    backgroundColor: COLORS.BLACK,
    borderColor: COLORS.BLACK,
    color: COLORS.WHITE,
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    padding: '10px 84px',
  },
});

const BlueLink = styled(TransparentLink)({
  borderColor: COLORS.BLUE,
  backgroundColor: COLORS.BLUE,
  color: COLORS.WHITE,
  transition: 'background-color 0.4s ease, border-color 0.4s ease',
  '&:hover': {
    opacity: 1,
    backgroundColor: COLORS.LIGHT_DARK_GREY,
    borderColor: COLORS.LIGHT_DARK_GREY,
    color: COLORS.WHITE,
  },
});
