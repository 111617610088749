import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { Image } from '../../types/basicTypes';

interface HelmetHeaderProps {
  language: string;
  title: string;
}

const getLanguageName = (languageNameWithCountry: string) => {
  return languageNameWithCountry.slice(0, 2);
};

const helmetHeaderData = (language: string) => {
  const { allContentfulLandingPage, site } = useStaticQuery(graphql`
    query LayoutDataQuery {
      allContentfulLandingPage {
        nodes {
          node_locale
          heroDescription {
            heroDescription
          }
          heroImage {
            file {
              url
            }
          }
          siteName
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const openGraph = allContentfulLandingPage.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );

  return { openGraph, site };
};

type OpenGraph = {
  heroImage: Image;
  heroDescription: { heroDescription: string };
  siteName: string;
};

export type Site = {
  siteMetadata: {
    siteUrl: string;
  };
};

type Data = {
  openGraph: OpenGraph;
  site: Site;
};

const HelmetHeader: React.FC<HelmetHeaderProps> = ({ language, title }) => {
  const { openGraph, site }: Data = helmetHeaderData(language);

  const href =
    typeof window !== 'undefined'
      ? window.location.href
      : site.siteMetadata.siteUrl;

  const imageUrl = `https:${openGraph.heroImage.file.url}`;

  return (
    <Helmet
      defer={false}
      title={`${openGraph.siteName} - ${title}`}
      meta={[
        {
          name: 'description',
          content: openGraph.heroDescription.heroDescription,
        },
        { name: 'keywords', content: 'Fiskars, Arabia' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
      ]}
    >
      <link rel="canonical" href={href} />
      <html lang={getLanguageName(language)} />

      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={getLanguageName(language)} />
      <meta property="og:site_name" content={openGraph.siteName} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={openGraph.heroDescription.heroDescription}
      />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />

      <meta name="twitter:card" content={'summary_large_image'} />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={openGraph.heroDescription.heroDescription}
      />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default HelmetHeader;
