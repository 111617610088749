import { graphql, useStaticQuery } from 'gatsby';

export function useEnableShopping(): boolean {
  const { allContentfulMenu } = useStaticQuery(graphql`
    query EnableShoppingQuery {
      allContentfulMenu {
        nodes {
          enableShopping
        }
      }
    }
  `);
  return allContentfulMenu.nodes[0].enableShopping;
}
