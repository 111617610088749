import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../storage/session-storage';
import Banner from '../banner';

interface CookieBannerData {
  buttonLabel: string;
  description: { description: string };
  readMore: {
    link: string;
    text: string;
  };
}

const getCookieBannerData: (
  language: string
) => CookieBannerData = language => {
  const { allContentfulCookieBanner } = useStaticQuery(graphql`
    query CookieBannerQuery {
      allContentfulCookieBanner {
        nodes {
          buttonLabel
          description {
            description
          }
          readMore {
            link
            text
          }
          node_locale
        }
      }
    }
  `);
  return allContentfulCookieBanner.nodes.find(
    ({ node_locale: local }: { node_locale: string }) => local === language
  );
};

const ReadMore = styled.a`
  color: var(--dark-grey);
  font-weight: bold;
`;

export interface CookieBannerProps {
  language: string;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ language }) => {
  const key = 'cookieAgreement';
  const isAgreement = () => getFromLocalStorage(key) === 'true';
  const [show, setShow] = useState(false);
  const setAgreement = () => {
    if (setToLocalStorage(key, 'true')) setShow(false);
  };
  const {
    buttonLabel,
    description: { description },
    readMore: { text, link },
  } = getCookieBannerData(language);

  useEffect(() => {
    setShow(!isAgreement());
  }, []);

  return (
    <Banner
      show={show}
      confirmBtnLabel={buttonLabel}
      confirmBtnClicked={setAgreement}
    >
      {description}&nbsp;
      <ReadMore target="_blank" href={link}>
        {text}
      </ReadMore>
    </Banner>
  );
};

export default CookieBanner;
