import React from 'react';

const ShoppingCart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <g fill="none" fillRule="evenodd">
      <rect width="19" height="16" x=".5" y="4.5" rx="2" />
      <path d="M6.502 4.5h6.996l.002-.125C13.5 2.225 11.922.5 10 .5S6.5 2.225 6.5 4.375c0 .042 0 .083.002.125z" />
    </g>
  </svg>
);

export default ShoppingCart;
