import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';

import { MEDIA_QUERY } from '../../commonStyles';
import { i18n } from '../../translations';
import * as Icon from '../../assets/icons';
import { Logos } from '../header/logo';

interface FooterProps {
  language: string;
}

const footerData = (language: string) => {
  const { allContentfulFooter } = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulFooter {
        nodes {
          node_locale
          facebookLink {
            text
            link
          }
          arabiaLink {
            text
            link
          }
          arabiaEmail {
            label
            email
          }
          termsOfUseLink {
            text
            link
          }
          privacyPolicyLink {
            link
            text
          }
          instagramLink {
            link
            text
          }
          faqLink {
            link
            text
          }
          copyrights
          contactInformation
        }
      }
    }
  `);
  return allContentfulFooter.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Link = {
  text: string;
  link: string;
};

type Email = {
  label: string;
  email: string;
};

type Data = {
  facebookLink: Link;
  arabiaLink: Link;
  arabiaEmail: Email;
  termsOfUseLink: Link;
  privacyPolicyLink: Link;
  instagramLink: Link;
  faqLink: Link;
  copyrights: string;
  contactInformation: string;
};

const Footer: React.FC<FooterProps> = ({ language }) => {
  const __ = i18n(language);
  const {
    arabiaEmail,
    termsOfUseLink,
    privacyPolicyLink,
    faqLink,
    copyrights,
    contactInformation,
  }: Data = footerData(language);

  return (
    <FooterContainer>
      <LogosContainer>
        <Logos insideFooter />
      </LogosContainer>
      <FooterLinks>
        <LocalLink to={faqLink.link}>{faqLink.text}</LocalLink>
        <LocalLink to={termsOfUseLink.link}>{termsOfUseLink.text}</LocalLink>
        <ExternalLink
          href={privacyPolicyLink.link}
          target="_blank"
          rel="nofollow"
        >
          {privacyPolicyLink.text}
        </ExternalLink>
      </FooterLinks>

      <FooterParagraph>
        <ContactInformation
          label={__('contactInformation')}
          value={contactInformation}
        />
        <FooterEmailLink label={arabiaEmail.label} value={arabiaEmail.email} />
        <Emails>
          <FooterLink
            label={'www.iittala.fi'}
            value={'https://www.iittala.fi'}
          />
          <FooterLink label={'www.fiskars.fi'} value={'https://fiskars.fi'} />
          <FooterLink label={'www.arabia.fi'} value={'https://arabia.fi'} />
          <FooterLink label={'www.hackman.fi'} value={'http://hackman.fi'} />
        </Emails>
        <CopyRights>{copyrights}</CopyRights>
      </FooterParagraph>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  padding: 0 15px;
  margin: 0 0 120px 0;
`;

const LogosContainer = styled.div`
  padding: 0;
  border: none;
  border-top: solid 1px #d8d8d8;
  text-align: center;
  overflow: visible;
  max-width: 1248px;
  margin: 0 auto;
  padding: 42px 0;
  position: relative;
  display: flex;
  justify-content: center;
`;

const FooterLinks = styled.div`
  display: flex;
  max-width: 688px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  position: relative;
  a {
    margin: 0 12px;
  }
  ${MEDIA_QUERY.MOBILE} {
    flex-wrap: wrap;
    padding: 0 15px;
  }
`;

const linkStyles = `
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  flex: 0 auto;
  display: block;
  ${MEDIA_QUERY.MOBILE} {
    flex: 1 100%;
    line-height: 3.21;
    text-align: center;
  }
`;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const LocalLink = styled(GatsbyLink)`
  ${linkStyles}
`;

const FooterParagraph = styled.div`
  margin-top: 34px;
  font-size: 11px;
  color: #949594;
  font-family: Arial;
  line-height: 3.21;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
`;

const Emails = styled.div`
  width: 100%;
  text-align: center;
`;

const FooterLinkLight = styled.a`
  font-family: Arial;
  font-size: 11px;
  font-weight: normal;
  text-align: center;
  color: #949594;
  text-decoration: underscore;
`;

const Emphasized = styled.span`
  color: #000000;
  font-weight: bold;
`;

interface LabelValueProps {
  label: string;
  value: string;
}

const ContactInformationFC: React.FC<LabelValueProps &
  HTMLAttributes<HTMLDivElement>> = ({ label, value, ...props }) => (
  <div {...props}>
    <Emphasized>{label}:</Emphasized>
    <span>{value}</span>
  </div>
);

const ArabiaEmailFC: React.FC<LabelValueProps &
  HTMLAttributes<HTMLAnchorElement>> = ({ label, value, ...props }) => (
  <FooterLinkLight href={`mailto:${value}`} {...props}>
    {label}
  </FooterLinkLight>
);

const ArabiaWebPageFC: React.FC<LabelValueProps &
  HTMLAttributes<HTMLAnchorElement>> = ({ label, value, ...props }) => (
  <FooterLinkLight href={value} {...props}>
    {label}
  </FooterLinkLight>
);

const ContactInformation = styled(ContactInformationFC)`
  flex: 1 100%;
  text-align: center;
  span {
    :first-child {
      ::after {
        content: ' ';
      }
    }
  }
  ${MEDIA_QUERY.MOBILE} {
    span {
      display: block;
      line-height: 1.5;
      :last-child {
        margin-bottom: 10px;
      }
    }
  }
`;

const FooterEmailLink = styled(ArabiaEmailFC)`
  flex: 1 40%;
  text-align: center;
  padding-right: 15px;
  ${MEDIA_QUERY.MOBILE} {
    padding-right: 0;
    text-align: center;
    flex: 1 100%;
  }
`;

const FooterLink = styled(ArabiaWebPageFC)`
  flex: 1 40%;
  text-align: center;
  padding: 0 8px;
  ${MEDIA_QUERY.MOBILE} {
    text-align: center;
    flex: 1 100%;
  }
  ${MEDIA_QUERY.EXTRA_SMALL_MOBILE} {
    padding: 0 2px;
  }
`;

const CopyRights = styled.div`
  flex: 0 100%;
  text-align: center;
`;

export default Footer;
