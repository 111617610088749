import React from 'react';
import globalHook, { Store } from 'use-global-hook';
import { Product } from '../types/basicTypes';
import {
  getOrderedProducts,
  saveOrderedProducts,
  deleteOrderedProducts,
} from './session-storage';

export type ProductsState = {
  products: Product[];
};

export type ProductsActions = {
  saveProducts: (products: Product[]) => void;
  deleteProducts: () => void;
};

const saveProducts = (
  store: Store<ProductsState, ProductsActions>,
  products: Product[]
) => {
  store.setState({ ...store.state, products });
  saveOrderedProducts(products);
};

const deleteProducts = (
  store: Store<ProductsState, ProductsActions>
) => {
  const products: Product[] = [];
  store.setState({ ...store.state, products });
  deleteOrderedProducts();
};

const initialState: ProductsState = {
  products: getOrderedProducts()
};

export const calculateTotal = (products: Product[]) =>
  products.reduce((total, item) => total + item.price, 0)

export const productsStore = globalHook<ProductsState, ProductsActions>(
  React,
  initialState,
  {
    saveProducts,
    deleteProducts
  }
);
