import React from 'react';

const ShoppingCartBold = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 21 26"
  >
    <g fill="none" fillRule="evenodd" transform="translate(-5 -2)">
      <path d="M0 0h30v30H0z" />
      <rect
        width="19"
        height="18"
        x="6"
        y="9"
        stroke="#000"
        strokeWidth="2"
        rx="2"
      />
      <path
        stroke="#000"
        strokeWidth="2"
        d="M10.022 9a5.5 5.5 0 1 1 10.955 0H10.023z"
      />
    </g>
  </svg>
);

export default ShoppingCartBold;
