import { graphql, useStaticQuery } from 'gatsby';
import { Category } from '../../types/basicTypes';
import { getLink } from '../../constants/links';

export function formatPrice(price: number): string {
  return price.toLocaleString('fi-FI', {
    minimumFractionDigits: 2,
  });
}

export function slugify(str: string) {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function normalizeTitle(title: string): string {
  return slugify(title);
}

export function makeQuery(query: string): string {
  return query.replace('@media', '');
}

export function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export function sleep(ms: number) {
  return new Promise(res => setTimeout(res, ms));
}

export function useCategoryFirstProductLink(
  language: string,
  category: Category,
  absolute = false
): string {
  const { allContentfulProduct } = useStaticQuery(graphql`
    query CategoryFirstProductLinks {
      allContentfulProduct {
        nodes {
          id
          contentful_id
          title
          node_locale
          category {
            contentful_id
          }
        }
      }
    }
  `);

  const products =
    allContentfulProduct.nodes.filter(n => n.node_locale === language) || [];

  const categoryFirst = products.find(
    p => p.category.contentful_id === category.contentful_id
  );

  const productId = categoryFirst
    ? `#${normalizeTitle(categoryFirst.title)}`
    : '';

  return absolute
    ? `${getLink(language, 'productPicker')}${productId}`
    : productId;
}
