import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  getFromSessionStorage,
  setToSessionStorage,
} from '../../storage/session-storage';
import Banner from '../banner';

interface BannerData {
  buttonLabel: string;
  description: { description: string };
}

const getIE11BannerData: (language: string) => BannerData = language => {
  const { allContentfulIe11Banner } = useStaticQuery(graphql`
    query IE11BannerQuery {
      allContentfulIe11Banner {
        nodes {
          buttonLabel
          description {
            description
          }
          node_locale
        }
      }
    }
  `);
  return allContentfulIe11Banner.nodes.find(
    ({ node_locale: local }: { node_locale: string }) => local === language
  );
};

export interface BannerProps {
  language: string;
}

const IE11Banner: React.FC<BannerProps> = ({ language }) => {
  const key = 'ie11Informed';

  const isIE = () => {
    if (typeof window !== 'undefined') {
      return /*@cc_on!@*/ false || document.documentMode;
    }
    return false;
  };

  const isInformed = () => getFromSessionStorage(key) === 'true';
  const [show, setShow] = useState(isIE() && !isInformed());

  const setInformed = () => {
    if (setToSessionStorage(key, 'true')) setShow(false);
  };

  const {
    buttonLabel,
    description: { description },
  } = getIE11BannerData(language);

  useEffect(() => {
    setShow(isIE() && !isInformed());
  }, []);

  return (
    <Banner
      show={show}
      confirmBtnLabel={buttonLabel}
      confirmBtnClicked={setInformed}
    >
      {description}
    </Banner>
  );
};

export default IE11Banner;
