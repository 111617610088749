import React from 'react';

const InfoGrey = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g fill="none">
      <path d="M-1-1h36v36H-1z" />
      <path
        fill="#FFF"
        d="M17 0c2.39 0 4.516.443 6.64 1.328 2.037.886 3.896 2.125 5.402 3.63 1.505 1.506 2.744 3.365 3.63 5.401C33.557 12.396 34 14.61 34 17c0 2.39-.443 4.604-1.328 6.64-.886 2.037-2.125 3.896-3.63 5.402-1.506 1.505-3.365 2.744-5.401 3.63C21.604 33.557 19.39 34 17 34c-2.39 0-4.604-.443-6.64-1.328-2.037-.886-3.896-2.125-5.402-3.63-1.505-1.506-2.744-3.365-3.63-5.401C.443 21.604 0 19.39 0 17c0-2.39.443-4.516 1.328-6.64.886-2.037 2.125-3.896 3.63-5.402 1.506-1.505 3.365-2.744 5.401-3.63C12.396.443 14.61 0 17 0zm-2.48 27.2c.638 0 1.549-.265 2.915-.883s2.641-1.501 4.098-2.826l-.637-.795c-.547.442-1.093.707-1.64.972-.546.264-.82.353-1.001.353-.182 0-.273-.089-.273-.265 0-.177 0-.53.09-1.06l1.549-5.652c.273-1.06.364-1.854.273-2.473-.091-.618-.455-.971-1.093-.971-.728 0-1.73.353-3.187.971-1.457.619-2.824 1.502-4.28 2.65l.546.971c.455-.265 1.002-.53 1.639-.795.638-.265 1.002-.353 1.184-.353.182 0 .182.088.182.265 0 .177-.091.53-.182.972l-1.366 5.298c-.364 1.502-.455 2.561-.091 3.003.364.441.728.618 1.275.618zm2.102-19.534c-.566.597-.755 1.109-.755 1.706s.189 1.108.566 1.45c.378.34 1.04.511 1.89.511 1.038 0 1.794-.256 2.36-.767.567-.512.85-1.109.85-1.791 0-.512-.189-1.024-.472-1.365-.283-.426-.944-.597-1.794-.597-1.323-.085-2.173.256-2.645.853z"
        opacity=".498"
      />
    </g>
  </svg>
);

export default InfoGrey;
