import React from 'react';

const ShoppingCartCheckout = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17.77,21.73H6.23a2,2,0,0,1-2-2V8.92a2,2,0,0,1,2-2H17.77a2,2,0,0,1,2,2V19.69A2,2,0,0,1,17.77,21.73ZM6.23,7.88a1,1,0,0,0-1,1V19.69a1,1,0,0,0,1,1H17.77a1,1,0,0,0,1-1V8.92a1,1,0,0,0-1-1Z" />
    <path d="M16.67,7.88H7.33l0-.45a4.73,4.73,0,1,1,9.42-.85,4,4,0,0,1,0,.85Zm-8.4-1h7.46a1.49,1.49,0,0,0,0-.21,3.72,3.72,0,0,0-4.05-3.38A3.74,3.74,0,0,0,8.27,6.88Z" />
  </svg>
);

export default ShoppingCartCheckout;
