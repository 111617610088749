import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as Icon from '../../assets/icons';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import { getLink } from '../../constants/links';
import { calculateTotal, productsStore } from '../../storage/global-state';
import { i18n } from '../../translations';
import { formatPrice } from '../../functions/utils';
import { Product } from '../../types/basicTypes';

const EmptyCartLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${COLORS.BLACK};
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

const CartContainer = styled.div({
  flex: '1 33%',
  display: 'flex',
  justifyContent: 'flex-end',
  [MEDIA_QUERY.MOBILE_AND_TABLET]: {
    position: 'absolute',
    right: 0,
  },
  [MEDIA_QUERY.DESKTOP]: {
    position: 'relative',
  },
});

const CartLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '22px',
  color: COLORS.BLACK,
  backgroundColor: COLORS.LIGHT_BLUE,
  [MEDIA_QUERY.MOBILE]: {
    padding: '6px',
  },
  padding: '8px',
  textDecoration: 'none',
  cursor: 'pointer',
  stroke: '#000',
  '&:hover': {
    color: `${COLORS.WHITE} !important`,
    backgroundColor: `${COLORS.LIGHT_DARK_GREY} !important`,
    stroke: `${COLORS.WHITE} !important`,
  },
});

const ItemsCountContainer = styled.div({
  position: 'relative',
  display: 'flex',
});

const ItemsCount = styled.span({
  fontSize: '12px',
  textDecoration: 'none',
  fontWeight: 'bold',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '3px',
});

const TotalPrice = styled.span({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 8px',
  fontSize: '16px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '11px',
    margin: '0 4px',
    display: 'none',
  },
});

export interface CartProps {
  language: string;
  show: boolean;
}

interface CartRendererProps {
  show: boolean;
  label: string;
  totalPrice: string;
  totalPriceUrl: string;
  itemsCount: number;
}
const NonEmptyCartRenderer: React.FC<CartRendererProps> = ({
  label,
  totalPrice,
  totalPriceUrl,
  itemsCount,
}) => (
  <CartLink to={totalPriceUrl}>
    <TotalPrice>
      {totalPrice} {label}
    </TotalPrice>
    <ItemsCountContainer>
      <Icon.ShoppingCart />
      <ItemsCount>{itemsCount}</ItemsCount>
    </ItemsCountContainer>
  </CartLink>
);

const EmptyCartRenderer: React.FC<CartRendererProps> = ({ totalPriceUrl }) => (
  <EmptyCartLink to={totalPriceUrl}>
    <Icon.ShoppingCartBold />
  </EmptyCartLink>
);

const NoneRenderer: React.FC<CartRendererProps> = () => null;

const CompoundRenderer: React.FC<CartRendererProps> = props => {
  const { show, itemsCount } = props;

  if (show && itemsCount) return <NonEmptyCartRenderer {...props} />;
  if (show) return <EmptyCartRenderer {...props} />;
  return <NoneRenderer {...props} />;
};

export const Cart: React.FC<CartProps> = ({ language, show = true }) => {
  const [state] = productsStore();

  const [products, setProducts] = useState<Product[]>([]);

  // During hydration there are no products, so we end up with icon that won't load on production builds
  useEffect(() => {
    setProducts(state.products);
  }, [state.products]);

  const totalPrice = formatPrice(calculateTotal(products) / 100);
  const __ = i18n(language);

  return (
    <CartContainer>
      <CompoundRenderer
        show={show}
        label={__('shoppingCart.eurPerMonth')}
        totalPrice={totalPrice}
        totalPriceUrl={getLink(language, 'shoppingCart')}
        itemsCount={products.length}
      />
    </CartContainer>
  );
};
