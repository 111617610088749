import React from 'react';

export default () => (
  <svg width="33px" height="36px" viewBox="0 0 33 36" version="1.1">
    <defs>
      <circle
        id="path-1"
        cx="17.0666667"
        cy="14.9333333"
        r="13.8666667"
      ></circle>
      <filter
        x="-25.2%"
        y="-18.0%"
        width="150.5%"
        height="150.5%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Artboards"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Shopping-Cart-upsell---Tablet"
        transform="translate(-735.000000, -1018.000000)"
      >
        <g
          id="UI-/-Navigation-/-Chevron-white-forward"
          transform="translate(736.000000, 1019.000000)"
        >
          <g id="Oval-Copy">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            ></use>
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
          </g>
          <path
            d="M12.0086022,6.67286822 C11.8434409,6.50542636 11.7883871,6.3379845 11.7883871,6.11472868 C11.7883871,5.89147287 11.8434409,5.72403101 12.0086022,5.55658915 C12.1737634,5.38914729 12.3389247,5.33333333 12.5591398,5.33333333 C12.7793548,5.33333333 12.9445161,5.38914729 13.1096774,5.55658915 L21.7531183,14.3751938 C21.9182796,14.5426357 21.9733333,14.7100775 21.9733333,14.9333333 C21.9733333,15.1565891 21.9182796,15.324031 21.7531183,15.4914729 L13.0546237,24.3100775 C12.8894624,24.4775194 12.7243011,24.5333333 12.504086,24.5333333 C12.283871,24.5333333 12.1187097,24.4775194 11.9535484,24.3100775 C11.7883871,24.1426357 11.7333333,23.9751938 11.7333333,23.751938 C11.7333333,23.5286822 11.7883871,23.3612403 11.9535484,23.1937984 L19.8262366,14.9333333 L12.0086022,6.67286822 Z"
            id="Path-Copy"
            fill="#333333"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
