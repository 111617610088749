import React from 'react';
import { ButtonIcon } from './containers';

const Plus = () => (
  <ButtonIcon
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h30v30H-1z" />
      <g>
        <circle cx="14" cy="14" r="13" fill="#EBEBEB" />
        <path
          id="rectangle"
          fill="#000"
          d="M13 13V8h2v5h5v2h-5v5h-2v-5H8v-2h5z"
        />
      </g>
    </g>
  </ButtonIcon>
);

export default Plus;
