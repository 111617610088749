import { createGlobalStyle } from 'styled-components';

const NormalizeStyles = createGlobalStyle`
body {
  margin: 0;
  background-color: #f5f5f5;
}

img {
  max-width: 100%;
  padding:0;
  margin:0;
}

.disable-scroll {
  overflow: hidden;
  padding-right: calc(100vw - 100%);
}
`;

export default NormalizeStyles;
