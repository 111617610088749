import React from 'react';

const NextModal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="36"
    viewBox="0 0 20 36"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-22-14h64v64h-64z" />
      <g stroke="#FFF" strokeLinecap="square">
        <path d="M1 0l18 18M19 18L1 36" />
      </g>
    </g>
  </svg>
);

export default NextModal;
