import { Product } from '../types/basicTypes';

const orderedProductsKey = 'orderedProducts';

export const saveOrderedProducts = (products: Record<any, any>) => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(orderedProductsKey, JSON.stringify(products));
};

export const getOrderedProducts = (): Product[] => {
  if (typeof window === 'undefined') return [];
  const rawProducts = localStorage.getItem(orderedProductsKey);
  return rawProducts ? JSON.parse(rawProducts) : [];
};

export const deleteOrderedProducts = () => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(orderedProductsKey, JSON.stringify([]));
};

export const getTotalPrice = (
  products: Product[] = getOrderedProducts()
): number => {
  return products
    .reduce((total, nextItem) => total + nextItem.price, 0);
};

export const getFromLocalStorage: (key: string) => string | null = key => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
  return null;
};

export const setToLocalStorage: (key: string, value: string) => boolean = (
  key,
  value
) => {
  if (typeof window === 'undefined') return false;
  localStorage.setItem(key, value);
  return true;
};

export const getFromSessionStorage: (key: string) => string | null = key => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(key);
  }
  return null;
};

export const setToSessionStorage: (key: string, value: string) => boolean = (
  key,
  value
) => {
  if (typeof window === 'undefined') return false;
  sessionStorage.setItem(key, value);
  return true;
};
