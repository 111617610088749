import React from 'react';

const LongArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="14"
    viewBox="0 0 30 14"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-1-3h32v20H-1z" />
      <path
        fill="#000"
        d="M3.672 8l4.4 4.4-1.415 1.414L1.414 8.57 0 7.157 6.657.5 8.07 1.914 3.985 6H30v2H3.672z"
      />
    </g>
  </svg>
);

export default LongArrow;
