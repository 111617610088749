import get from 'lodash/get';

const translations = {
  'en-US': {
    title: 'Arabia Tableware Service',
    faq: 'FAQ',
    termsOfUse: 'TERMS OF USE',
    privacyPolicy: 'PRIVACY POLICY',
    contactInformation: 'Contact information',
    address: 'Address',
    addToShoppingCart: 'add to cart',
    productPicker: {
      forPersonsPre: 'for ',
      forPersonsPost: ' ',
      contains: 'Contains:',
      seeTheService: 'See the service',
      faq: 'frequently asked questions',
      fixed: 'Fixed size',
    },
    upsell: {
      title: 'You might also like these',
    },
    header: {
      continueShopping: 'Continue shopping',
    },
    shoppingCart: {
      yourOrder: 'Your order',
      inTotal: 'In total',
      or: 'or',
      eurPerMonth: ' €/month',
      eurPerYear: ' €/year',
      alternativeWayOfPurchase:
        'if you want to buy the products of your choice in the traditional way',
      orderNow: 'Order now',
      for: (count: number) => `for ${count}`,
      empty: `You didn't select anything.`,
      emptyCart: 'You have no items in your shopping cart',
      continueShopping: 'Continue shopping.',
      ship: 'The service includes free delivery to your home address.',
      terms: 'I have read the Terms of Service',
      inVat: '(inc. VAT)',
    },
    userDataForm: {
      checkout: 'Checkout',
      customerInformation: '1. Customer information',
      firstName: 'First name',
      lastName: 'Last name',
      birthday: 'Birthday',
      email: 'Email address',
      confirmEmail: 'Confirm email address',
      phonePrefix: 'Country code',
      phoneNumber: 'Phone number',
      streetAddress: 'Street address',
      zipCode: 'Zip code',
      city: 'City',
      additionalAddress:
        'The products will be delivered to your home address. If you want to ship to another address, you can add a separate shipping address below.',
      additionalAddressCheckbox: 'Ship to another address',
      additionalAddressTitle: 'Shipping Address',
      payment: '2. Payment',
      cardholderName: `Cardholder's first name`,
      cardholderLastName: `Cardholder's last name`,
      cardNumber: `Credit card number`,
      expirationDate: 'Expiration date (mm/year)',
      cvv: 'CVV',
      whatIsCvv: 'What is CVV?',
      submitCheckboxTerms: (url: string) =>
        `I have read the <a href="${url}" target="_blank">Terms of Use</a> and I commit to follow them.`,
      submitCheckboxNewsletter:
        'Yes, I want to receive newsletters and other digital direct marketing from the Fiskars group',
      submitTextPrivacy: (url: string) =>
        `You can find additional information about our personal data handling policies from our <a href="${url}" target="_blank">Privacy Policy</a>.`,
      submitOrderNow: 'Order now',
      errorFillFields:
        'Please fill in in all required fields marked with an asterisk * ',
      errorOrderSomething: 'You need to add something to your basket',
      errorAge: 'You have to be at least 18 years old to place the order.',
      errorCardDataMissing: 'Please check your card details.',
      errorRecurly:
        'There is a problem with finalizing your order. Please contact support. Error details: ',
      errorException:
        'There is a problem with finalizing your order. Please contact support. ',
      support:
        'There is a problem with finalizing your order. Please contact support.',
      transaction_declined:
        'There is a problem with finalizing your order, the credit card transaction was declined.',
      validation_error:
        'There is a problem with validating your order. Please contact support.',
      errorEmailsDontMatch: 'Please check that your email address is correct.',
      placeholder: {
        birth: {
          day: 'dd',
          month: 'mm',
          year: 'yyyy',
        },
      },
      popup: {
        text: (price: string) =>
          `Please note that you are hereby concluding a fixed term contract with the minimum period of 12 months. The total price for the ordered products is ${price} €. For more information, please see the Terms of Service.`,
        processing: 'We process your order. Just one moment…',
        cancel: 'Cancel',
        close: 'Close',
        accept: 'Continue',
        securityFailure:
          'Unfortunately, an unexpected problem related to your card security occurred. Please try again later or contact your bank.',
        additionalSecurity:
          'We need you to complete one more additional security check to confirm your identity in order to proceed with your request.',
      },
      automaticAccountCreation:
        'If you are not a member of MyIittala, we will create a membership for you so that we can keep you informed.',
    },
    thankYou: {
      exploreArabia: 'Explore arabia.fi',
      exploreArabiaHref: 'https://arabia.fi/eng/',
    },
    serviceMaintenance: {
      title: 'Service maintenance',
      subtitle:
        'We’re polishing some plates for you.\n Please come back a bit later for an even shinier experience.\n',
    },
    languages: {
      en: 'EN',
      fi: 'FI',
    },
    endOfService: {
      btnLabel: 'OK',
      text:
        'The Arabia Tableware Service pilot has ended. Subscriptions made before May 31st, 2020 will remain active according to the Terms of Use. New subscriptions are no longer accepted. We thank you for your interest towards the pilot and Arabia.',
    },
  },
  'fi-FI': {
    title: 'Arabia Astiapalvelu',
    faq: 'FAQ',
    termsOfUse: 'TERMS OF USE',
    privacyPolicy: 'PRIVACY POLICY',
    contactInformation: 'Yhteystiedot',
    addToShoppingCart: 'Lisää ostoskoriin',
    productPicker: {
      forPersonsPre: ' ',
      forPersonsPost: ':lle',
      contains: 'Sisältää:',
      seeTheService: 'TUTUSTU PALVELUUN',
      faq: 'usein kysytyt kysymykset',
      fixed: 'Kiinteäkokoinen',
    },
    header: {
      continueShopping: 'Jatka ostoksia',
    },
    upsell: {
      title: 'Voisit pitää myos näistä',
    },
    shoppingCart: {
      yourOrder: 'Tilauksesi',
      inTotal: 'Yhteensä',
      or: 'tai',
      eurPerMonth: ' €/kk',
      eurPerYear: ' €/vuosi',
      alternativeWayOfPurchase:
        'mikäli haluat ostaa valitsemasi tuotteet perinteisellä tavalla',
      orderNow: 'tilaa nyt',
      for: (count: number) => `${count}:lle`,
      empty: 'Et valinnut mitään.',
      emptyCart: 'Ostoskorisi on vielä tyhjä.',
      continueShopping: 'Jatka ostoksia.',
      ship: 'Palveluun kuuluu ilmainen kuljetus kotiisi.',
      terms: 'Olen lukenut palvelun käyttöehdot',
      inVat: '(sis. ALV)',
    },
    userDataForm: {
      checkout: 'Tarkista',
      customerInformation: '1. Tilaajan tiedot',
      firstName: 'Etunimi',
      lastName: 'Sukunimi',
      birthday: 'Syntymäpäivä',
      email: 'Sähköpostiosoite',
      confirmEmail: 'Vahvista sähköpostiosoite',
      phonePrefix: 'Maatunnus',
      phoneNumber: 'Matkapuhelinnumero',
      streetAddress: 'Katuosoite',
      zipCode: 'Postinumero',
      city: 'Postitoimipaikka',
      additionalAddress:
        'Tuotteet toimitetaan kotiisi. Jos haluat toimituksen toiseen osoitteeseen voit lisätä erillisen toimitusosoitteen alta.',
      additionalAddressCheckbox: 'Toimitus toiseen osoitteeseen',
      additionalAddressTitle: 'Toimitusosoite',
      payment: '2. Maksutapa',
      cardholderName: `Kortinhaltijan etunimi`,
      cardholderLastName: `Kortinhaltijan sukunimi`,
      cardNumber: `Kortin numero`,
      expirationDate: 'Voimassa (kk/vuosi)',
      cvv: 'CVV',
      whatIsCvv: 'Mikä on CVV?',
      submitCheckboxTerms: (url: string) =>
        `Olen lukenut palvelun käyttöehdot ja sitoudun <a href="${url}" target="_blank">niihin</a>.`,
      submitCheckboxNewsletter:
        'Kyllä, haluan vastaanottaa Fiskars Groupin uutiskirjeitä ja muuta sähköistä suoramarkkinointia.',
      submitTextPrivacy: (url: string) =>
        `Lisätietoja henkilötietojen käsittelystä löydät <a href="${url}" target="_blank">Tietosuojaselosteestamme</a>.`,
      submitOrderNow: 'Tilaa nyt',
      errorFillFields: 'Täytä kaikki tähdellä merkityt pakolliset kentät *',
      errorOrderSomething: 'Sinun on lisättävä jotain koriin',
      errorCardDataMissing: 'Tarkista korttisi tiedot.',
      errorAge:
        'Sinun on oltava vähintään 18-vuotias tilauksen tekemistä varten.',
      errorRecurly:
        'Tilauksen viimeistelyssä on ongelma. Ota yhteyttä tukeen. Virhetiedot: ',
      errorException:
        'Tilauksen viimeistelyssä on ongelma. Ota yhteyttä tukeen. ',
      support: 'Tilauksen viimeistelyssä on ongelma. Ota yhteyttä tukeen.',
      transaction_declined:
        'Tilauksen viimeistelyssä on ongelma, luottokorttitapahtuma hylättiin.',
      validation_error:
        'Tilauksen vahvistamisessa on ongelma. Ota yhteyttä tukeen.',
      errorEmailsDontMatch: 'Tarkistathan että sähköpostiosoite on oikein.',
      placeholder: {
        birth: {
          day: 'pp',
          month: 'kk',
          year: 'vvvv',
        },
      },
      popup: {
        text: (price: string) =>
          `Huomaathan, että olet sitoutumassa määräaikaiseen sopimukseen, jonka kesto on vähintään 12 kuukautta. Tilaamiesi tuotteiden kokonaishinta on ${price} €. Lisätietoja saat Käyttöehdoista.`,
        processing: 'Käsittelemme tilaustasi, pieni hetki…',
        cancel: 'Peruuta',
        close: 'Kiinni',
        accept: 'Tilaa',
        securityFailure:
          'Valitettavasti tapahtui odottamaton korttisi turvallisuuteen liittyvä ongelma. Yritä myöhemmin tai ota yhteyttä pankkiisi.',
        additionalSecurity:
          'Sinun on suoritettava vielä yksi ylimääräinen turvatarkastus henkilöllisyyden vahvistamiseksi, jotta voimme jatkaa pyyntöäsi.',
      },
      automaticAccountCreation:
        'Jos et ole MyIittala-jäsen, luomme sinulle jäsenyyden, jotta voimme tiedottaa sinua jatkossa.',
    },
    thankYou: {
      exploreArabia: 'Tutustu arabia.fi',
      exploreArabiaHref: 'https://arabia.fi/',
    },
    serviceMaintenance: {
      title: 'Palveluhuolto',
      subtitle:
        'Kiillotamme sinulle joitain levyjä.\n Palaa vähän myöhemmin saadaksesi vieläkin loistavamman kokemuksen.\n',
    },
    languages: {
      en: 'EN',
      fi: 'FI',
    },
    endOfService: {
      btnLabel: 'OK',
      text:
        'Arabia astiapalvelun pilottijakso on päättynyt. Ennen 31.5.2020 tehdyt tilaukset ovat voimassa käyttöehtojen mukaisesti. Uusia tilauksia ei enää oteta vastaan. Kiitämme mielenkiinnostasi pilottia ja Arabiaa kohtaan.',
    },
  },
};

export const i18n = (language: string) => {
  return (key: string) => {
    const translation = get(translations[language], key);
    return translation ? translation : key;
  };
};

export default translations;
