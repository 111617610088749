import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';

export const GrayContainer = styled.svg`
  ${MEDIA_QUERY.DESKTOP} {
    #rectangle,
    circle {
      transition-duration: 0.2s;
    }
    &:hover {
      circle {
        fill: ${COLORS.LIGHT_DARK_GREY};
      }
      #rectangle {
        fill: ${COLORS.WHITE};
      }
    }
  }
`;

export const ButtonIcon = styled.svg`
  circle {
    fill: ${COLORS.WHITE};
    transition: fill 0.1s linear;
    box-sizing: border-box;
  }
  height: 100%;
  width: 100%;
  #rectangle {
    transition: fill 0.1s linear;
  }
  :active {
    circle {
      fill: ${COLORS.LIGHT_DARK_GREY};
    }
    #rectangle {
      fill: ${COLORS.WHITE};
    }
  }
  @media (hover: hover) {
    @media not all and (any-pointer: coarse) {
      :hover {
        circle {
          fill: ${COLORS.LIGHT_DARK_GREY};
        }
        #rectangle {
          fill: ${COLORS.WHITE};
        }
      }
    }
  }
`;
