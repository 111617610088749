import React from 'react';

const Cvv = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="75"
    viewBox="0 0 120 75"
  >
    <g fill="none" fillRule="evenodd">
      <rect width="119" height="74" x=".5" y=".5" stroke="#28292B" rx="8" />
      <path stroke="#28292B" d="M15.5 37.5h59v14h-59z" />
      <path fill="#28292B" d="M0 15h120v15H0z" />
      <circle cx="87" cy="40" r="14.5" fill="#FFF" stroke="#F00" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M77.044 44l2.769-3.73-2.442-3.428H78.5l1.299 1.836c.27.38.462.674.576.879.12-.196.256-.397.408-.606l1.599-2.11h1.03l-2.515 3.375L83.606 44h-1.171l-1.802-2.554c-.101-.146-.205-.306-.313-.478l-.258.412-.083.125L78.182 44h-1.138zm6.92 0l2.768-3.73-2.441-3.428h1.128l1.299 1.836c.27.38.462.674.576.879.12-.196.256-.397.408-.606l1.599-2.11h1.03l-2.515 3.375L90.526 44h-1.172l-1.801-2.554c-.101-.146-.205-.306-.313-.478l-.205.33a6.01 6.01 0 0 1-.137.207L85.102 44h-1.138zm6.92 0l2.768-3.73-2.441-3.428h1.128l1.299 1.836c.27.38.462.674.576.879.12-.196.256-.397.408-.606l1.599-2.11h1.03l-2.515 3.375L97.446 44h-1.172l-1.801-2.554c-.101-.146-.205-.306-.313-.478l-.205.33a6.01 6.01 0 0 1-.137.207L92.021 44h-1.137z"
      />
    </g>
  </svg>
);

export default Cvv;
