import React from 'react';

const CloseModal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="36"
    viewBox="0 0 38 36"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity="0" d="M-13-14h64v64h-64z" />
      <g stroke="#FFF" strokeLinecap="square" opacity=".73">
        <path d="M1 0l36 36M37 0L1 36" />
      </g>
    </g>
  </svg>
);

export default CloseModal;
