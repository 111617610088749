import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getLink } from '../constants/links';
import { StickyFooter } from './StickyFooter';

interface FooterBoxProps {
  language: string;
}

const footerBoxData = (language: string) => {
  const { allContentfulFooter } = useStaticQuery(graphql`
    query FooterBoxQuery {
      allContentfulFooter {
        nodes {
          node_locale
          footerBoxTitle
        }
      }
    }
  `);
  return allContentfulFooter.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Data = {
  footerBoxTitle: string;
};

const FooterBox: React.FC<FooterBoxProps> = ({ language }) => {
  const { footerBoxTitle }: Data = footerBoxData(language);

  return (
    <StickyFooter
      to={`${getLink(language, 'productPicker')}#MainioSarastus`}
      title={footerBoxTitle}
    />
  );
};

export default FooterBox;
