import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from '../../commonStyles';

const Container = styled.div<{ show: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: solid 1px #000000;
  padding: 20px 30px;
  background-color: #f7efda;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.3px;
  text-align: justify;
  color: var(--dark-grey);
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

const Explanation = styled.div`
  text-align: left;
  max-width: 736px;
  flex: none;
  ${MEDIA_QUERY.TABLET} {
    max-width: 448px;
  }
  ${MEDIA_QUERY.MOBILE} {
    flex: 1 100%;
  }
`;

const ButtonContainer = styled.div`
  margin-left: 40px;
  flex: none;
  ${MEDIA_QUERY.MOBILE} {
    flex: 1 100%;
    text-align: right;
    margin-top: 20px;
  }
`;

const ConfirmButton = styled.button`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  background-color: #f7efda;
  border: solid 2px #000000;
  outline: none;
  cursor: pointer;
  padding: 19px 12px;
  width: 224px;
  ${MEDIA_QUERY.TABLET} {
    width: 208px;
  }
  ${MEDIA_QUERY.MOBILE} {
    width: 120px;
  }
`;

export interface BannerProps {
  show: boolean;
  confirmBtnLabel: string;
  confirmBtnClicked: () => void;
  hideable: boolean;
}

const Banner: React.FC<BannerProps> = ({
  show,
  confirmBtnLabel,
  confirmBtnClicked,
  children,
  hideable = true,
}) => {
  return (
    <Container show={show}>
      <Explanation>{children}</Explanation>
      {hideable && (
        <ButtonContainer>
          <ConfirmButton onClick={confirmBtnClicked}>
            {confirmBtnLabel}
          </ConfirmButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default Banner;
