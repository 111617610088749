import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { MEDIA_QUERY } from '../../commonStyles';

export interface LogoProps {
  linkUrl: string;
}

const LogoContainer = styled.div({
  transition: 'width 0.3s linear',
  width: '100%',
  [MEDIA_QUERY.DESKTOP]: {
    maxWidth: '992px',
    margin: 'auto',
  },
});

const DesktopLogosContainer = styled.div<InsideFooter>(
  {
    display: 'flex',
  },
  ({ insideFooter }) => ({
    [MEDIA_QUERY.TABLET_AND_MOBILE]: {
      display: insideFooter ? 'flex' : 'none',
    },
  })
);

const MobileLogosContainer = styled.div({
  display: 'flex',
  width: '100%',
  [MEDIA_QUERY.DESKTOP]: {
    display: 'none',
  },
});

const StyledLink = styled(Link)({
  display: 'flex',
  width: '100%',
  '.slick-slider': {
    width: '100%',
    '.slick-slide': {
      // Important to override the inline styles of the library
      transition: 'opacity 0.5s ease, visibility 0.5s ease!important',
    },
    '.slick-active': {
      transitionDelay: '0.3s !important',
    },
  },
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    justifyContent: 'center',
  },
});

const BrandLogo = styled.img<InsideFooter>(
  {
    padding: '0 8px',
    height: '16px',
    [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
      margin: '0 16px',
    },
  },
  ({ insideFooter }) => ({
    [MEDIA_QUERY.MOBILE_AND_TABLET]: {
      height: insideFooter ? '12px' : '16px',
      display: insideFooter ? 'block' : 'none',
    },
    [MEDIA_QUERY.EXTRA_SMALL_MOBILE]: {
      height: insideFooter ? '10px' : '16px',
    },
    [MEDIA_QUERY.DESKTOP]: {
      display: 'block',
    },
  })
);

type Logo = {
  id: string;
  logo: {
    file: {
      url: string;
    };
  };
};

const useLogos = (language: string): Logo[] => {
  const { allContentfulMenu } = useStaticQuery(graphql`
    query LogosQuery {
      allContentfulMenu {
        nodes {
          brandsLogos {
            id
            logo {
              file {
                url
              }
            }
          }
          node_locale
        }
      }
    }
  `);

  const filterLang = ({ node_locale: nodeLocale }: { node_locale: string }) =>
    nodeLocale === language;
  return allContentfulMenu.nodes.find(filterLang).brandsLogos;
};

export const MobileLogos: React.FC = () => {
  const slider = useRef(null);
  const logos = useLogos('en-US');

  const settings = {
    infinite: true,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    fade: true,
    autoplay: true,
    touchMove: false,
    swipe: false,
  };

  return (
    <MobileLogosContainer>
      <Slider ref={slider} {...settings}>
        {logos.map((logo, index) => (
          <BrandLogo key={`${logo.id}-${index}`} src={logo.logo.file.url} />
        ))}
      </Slider>
    </MobileLogosContainer>
  );
};

type InsideFooter = {
  insideFooter?: boolean;
};

export const Logos: React.FC<InsideFooter> = ({ insideFooter = false }) => {
  const logos = useLogos('en-US');

  return (
    <DesktopLogosContainer insideFooter={insideFooter}>
      {logos.map((logo, index) => (
        <BrandLogo
          insideFooter={insideFooter}
          key={`${logo.id}-${index}`}
          src={logo.logo.file.url}
        />
      ))}
    </DesktopLogosContainer>
  );
};

export const Logo: React.FC<JSX.IntrinsicAttributes & LogoProps> = React.memo(
  ({ linkUrl }) => {
    return (
      <LogoContainer>
        <StyledLink to={linkUrl}>
          <MobileLogos />
          <Logos />
        </StyledLink>
      </LogoContainer>
    );
  }
);
