import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { getLink, getPage } from '../../../constants/links';
import { i18n } from '../../../translations';
import {
  CategoryList,
  CategoryName,
  Container,
  Hideable,
  Item,
  Items,
  Language,
  Languages,
  Link,
  List,
  Mask,
  ProductLink,
  SubcategoryList,
  SubcategoryProductContainer,
  SubcategoryProductsList,
  SubcategoryTitle,
} from './ui';
import { normalizeTitle } from '../../../functions/utils';

type Props = {
  visible: boolean;
  language: string;
  hideMenu: () => void;
};

const useMenuData = (language: string) => {
  const { allContentfulMenu } = useStaticQuery(graphql`
    query MyQuery {
      allContentfulMenu {
        nodes {
          rangeTitle
          node_locale
          linksAfter {
            link
            text
          }
          linksBefore {
            link
            text
          }
          id
          categories {
            id
            items {
              slug
              node_locale
              id
              title
            }
            subcategories {
              items {
                slug
                node_locale
                id
                title
              }
              title
              node_locale
            }
            title
            node_locale
          }
        }
      }
    }
  `);
  const filterLang = ({ node_locale: nodeLocale }: { node_locale: string }) =>
    nodeLocale === language;
  return {
    allContentfulMenu: allContentfulMenu.nodes.find(filterLang),
  };
};

type Product = {
  name: string;
  category: Category;
};

type Subcategory = {
  items: Product[];
};

type Category = {
  id: string;
  name: string;
  items: Product[];
  subcategories: Subcategory[];
};

type Link = {
  text: string;
  link: string;
};

export const composeProductLink = (language: string, title: string) => {
  const base = getLink(language, 'productPicker');
  const normalizedTitle = normalizeTitle(title);
  return `${base}#${normalizedTitle}`;
};

const Subcategory = ({ title, language, items, categoryOpen, hideMenu }) => {
  const [isOpen, setOpen] = useState(false);
  // Close subcategory if category gets closed
  useEffect(() => {
    if (!categoryOpen && isOpen) {
      setOpen(false);
    }
  }, [categoryOpen]);

  return (
    <SubcategoryProductsList>
      <SubcategoryTitle active={isOpen} onClick={() => setOpen(!isOpen)}>
        {title}
      </SubcategoryTitle>
      <Hideable isOpen={isOpen} length={items.length} height={60}>
        {items.map(({ title }, index) => (
          <SubcategoryProductContainer key={`${title}-${index}`}>
            <ProductLink
              onClick={hideMenu}
              href={composeProductLink(language, title)}
            >
              {title}
            </ProductLink>
          </SubcategoryProductContainer>
        ))}
      </Hideable>
    </SubcategoryProductsList>
  );
};

const Category = ({ subcategories = [], title, language, items, hideMenu }) => {
  const [isOpen, setOpen] = useState(false);
  const subcats = subcategories ? subcategories : [];
  const products = items ? items : [];
  return (
    <SubcategoryList>
      <CategoryName onClick={() => setOpen(!isOpen)}>{title}</CategoryName>
      <Hideable isOpen={isOpen} length={subcats.length + products.length}>
        {products.map((p, index) => (
          <ProductLink
            onClick={hideMenu}
            key={`${p.title}-${index}`}
            href={composeProductLink(language, p.title)}
          >
            <SubcategoryTitle>{p.title}</SubcategoryTitle>
          </ProductLink>
        ))}
        {subcats.map(({ title, items }, index) => (
          <Subcategory
            hideMenu={hideMenu}
            language={language}
            categoryOpen={isOpen}
            key={`${title}-${index}`}
            title={title}
            items={items}
          />
        ))}
      </Hideable>
    </SubcategoryList>
  );
};

type CategoriesProps = {
  categories: Category[];
  language: string;
  hideMenu: () => void;
};

const Categories: React.FC<CategoriesProps> = ({
  categories,
  language,
  hideMenu,
}) => (
  <CategoryList>
    {categories.map(({ title, subcategories, items }, index) => (
      <Category
        hideMenu={hideMenu}
        language={language}
        key={`${title}-${index}`}
        title={title}
        subcategories={subcategories}
        items={items}
      />
    ))}
  </CategoryList>
);

const Menu: React.FC<Props> = ({ visible, hideMenu, language }) => {
  const { allContentfulMenu } = useMenuData(language);

  const { linksBefore, linksAfter, categories, rangeTitle } = allContentfulMenu;
  const { pathname, hash } =
    typeof window !== 'undefined'
      ? window.location
      : { pathname: '', hash: '' };

  const parts = pathname.split('/');
  const pageUrl = parts.pop() || parts.pop();
  const pageKey = getPage(language, pageUrl);

  const currentPage = pathname.concat(hash);
  const __ = i18n(language);

  const Links: React.FC<{ links: Link[] }> = ({ links }) => (
    <List>
      {links.map(({ link, text }, index) => (
        <Item
          onClick={hideMenu}
          active={link === currentPage}
          key={`${text}-${index}`}
        >
          <Link to={link}>{text}</Link>
        </Item>
      ))}
    </List>
  );

  return (
    <>
      <Mask onClick={hideMenu} visible={visible} />
      <Container visible={visible}>
        <Items>
          <Links links={linksBefore} />
          <Item>
            <Link to={getLink(language, 'productPicker')} onClick={hideMenu}>
              {rangeTitle}
            </Link>
          </Item>
          <Categories
            hideMenu={hideMenu}
            categories={categories}
            language={language}
          />
          <Links links={linksAfter} />
          <Languages>
            <Language active={language === 'en-US'}>
              <Link to={getLink('en-US', pageKey)}>{__('languages.en')}</Link>
            </Language>
            <Language active={language === 'fi-FI'}>
              <Link to={getLink('fi-FI', pageKey)}>{__('languages.fi')}</Link>
            </Language>
          </Languages>
        </Items>
      </Container>
    </>
  );
};

export default Menu;
