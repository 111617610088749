import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  text-align: center;
  mix-blend-mode: multiply;
  object-fit: contain;
`;

const Logo = () => (
  <Image src="../../../img/arabiaLogo.png" alt="Arabia logo" />
);

export default Logo;
