import React from 'react';
import { ButtonIcon } from './containers';

const Minus = () => (
  <ButtonIcon
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
      <circle cx="15" cy="15" r="13" fill="#EBEBEB" />
      <path id="rectangle" fill="#000" d="M9 14h12v2H9z" />
      <path d="M0 0h30v30H0z" />
    </g>
  </ButtonIcon>
);

export default Minus;
