import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import Footer from '../footer';
import FooterBox from '../footer-box';
import Header from '../header';
import { BurgerProps, BurgerRenderer } from '../header/burger-renderer';
import { GoBackProps } from '../header/go-back-renderer';
import IE11Banner from '../ie11-banner';
import NormalizeStyles from './normalize';
import CookieBanner from '../cookie-banner';
import HelmetHeader from './helmet-header';
import EndOfServiceBanner from '../end-of-service-banner';
import { useEnableShopping } from '../../functions/utils/hooks';

type MainContainerProps = {
  disableMaxWidth: boolean;
  fittingPadding: boolean;
};

const MainContainer = styled.div<MainContainerProps>`
  background-color: ${COLORS.WHITE};
  font-family: Arial;
  padding-top: 94px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${MEDIA_QUERY.MOBILE} {
    padding-top: 64px;
  }
  ${MEDIA_QUERY.TABLET} {
    padding-top: 126px;
  }
  ${MEDIA_QUERY.DESKTOP} {
    padding-top: 126px;
  }
  ${({ disableMaxWidth, fittingPadding }) => css`
    max-width: ${disableMaxWidth ? 'unset' : '1440px'};
    ${MEDIA_QUERY.MOBILE} {
      padding-top: 64px;
    }
    ${MEDIA_QUERY.TABLET} {
      padding-top: ${fittingPadding ? '84px' : '126px'};
    }
    ${MEDIA_QUERY.DESKTOP} {
      padding-top: ${fittingPadding ? '84px' : '126px'};
    }
  `}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface LayoutProps {
  language: string;
  title: string;
  headerHideCart?: boolean;
  headerLeftRenderer?: React.FC<BurgerProps & GoBackProps & {}>;
  headerHideShadow?: boolean;
  headerHideHamburger?: boolean;
  hideFooter?: boolean;
  hideFooterBox?: boolean;
  disableMaxWidth?: boolean;
  fittingPadding?: boolean;
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  language,
  title,
  headerHideCart = false,
  headerLeftRenderer = BurgerRenderer,
  headerHideShadow = false,
  hideFooter = false,
  hideFooterBox = false,
  headerHideHamburger = false,
  children,
  disableMaxWidth = false,
  fittingPadding = false,
  ...props
}) => {
  const enableShopping = useEnableShopping();
  return (
    <MainContainer
      fittingPadding={fittingPadding}
      disableMaxWidth={disableMaxWidth}
    >
      <HelmetHeader language={language} title={title} />
      <NormalizeStyles />
      <Header
        language={language}
        LeftRenderer={headerLeftRenderer}
        showCart={!headerHideCart && enableShopping}
        showHamburger={!headerHideHamburger}
        dropShadow={!headerHideShadow}
      />
      <Container {...props}>{children}</Container>
      {!hideFooter && <Footer language={language} />}
      {!hideFooterBox && <FooterBox language={language} />}
      <EndOfServiceBanner language={language} />
      <CookieBanner language={language} />
      <IE11Banner language={language} />
    </MainContainer>
  );
};
