import React from 'react';

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        d="M4.857 6.5L0 1.61 1.6 0 8 6.443l-.057.057.057.057L1.6 13 0 11.39 4.857 6.5z"
        opacity=".9"
      />
    </g>
  </svg>
);

export default Arrow;
