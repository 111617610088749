import React from 'react';

const PreviousModal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="36"
    viewBox="0 0 20 36"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity="0" d="M42 50h-64v-64h64z" />
      <g stroke="#FFF" strokeLinecap="square">
        <path d="M19 36L1 18M1 18L19 0" />
      </g>
    </g>
  </svg>
);

export default PreviousModal;
