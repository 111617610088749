export const links = {
  'en-US': {
    homepage: '',
    checkout: 'checkout',
    shoppingCart: 'shopping-cart',
    thankYou: 'thank-you',
    serviceMaintenance: 'service-maintenance',
    productPicker: 'product-picker',
    faq: 'frequently-asked-questions',
    aboutService: 'about-service',
    privacyPolicy: 'privacy-policy',
    termsOfUse: 'terms-of-use',
    notFound: '404',
  },
  'fi-FI': {
    homepage: '',
    checkout: 'tilaa-nyt',
    shoppingCart: 'ostoskori',
    thankYou: 'kiitos',
    serviceMaintenance: 'palvelun-yllapito',
    productPicker: 'tuotekerain',
    faq: 'usein-kysytyt-kysymykset',
    aboutService: 'tietoa-palvelusta',
    privacyPolicy: 'tietosuojakaytanto',
    termsOfUse: 'kayttoehdot',
    notFound: '404',
  },
};

export const getLink = (language, page) =>
  `/${language}/${links[language][page]}`;

export const getPage = (language, link) => {
  const element = Object.entries(links[language]).find(e => e[1] === link);
  return element !== undefined ? element[0] : 'homepage';
};
