import { Link as _Link } from 'gatsby';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../../commonStyles';
import { Button } from '../../ui';

type ContainerProps = {
  visible: boolean;
};

export const CloseButton = styled(Button)`
  width: 48px;
  height: 48px;
  padding: 0;
`;

export const Link = styled(_Link)({
  color: COLORS.BLACK,
  textDecoration: 'none',
  display: 'block',
  padding: '16px',
});

export const Container = styled.div(
  {
    transition: 'transform 0.3s ease-in-out',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '448px',
    height: '100%',
    padding: '0px 20px 20px 72px',
    backgroundColor: COLORS.WHITE,
    zIndex: 100,
    boxSizing: 'border-box',
    overflowY: 'auto',
    [MEDIA_QUERY.TABLET]: {
      width: '368px',
      paddingLeft: '38px',
      paddingRight: '24px',
    },
    [MEDIA_QUERY.MOBILE]: {
      padding: '14px',
      width: '100%',
    },
  },
  ({ visible }: ContainerProps) =>
    visible
      ? {
          [MEDIA_QUERY.MOBILE]: {
            transform: 'translate(0px)',
            boxShadow: 'none',
          },
          [MEDIA_QUERY.TABLET]: {
            transform: 'translate(0px)',
          },
          [MEDIA_QUERY.DESKTOP]: {
            transform: 'translate(0px)',
          },
        }
      : {
          [MEDIA_QUERY.MOBILE]: {
            transform: 'translate(0%, -100%)',
          },
          [MEDIA_QUERY.TABLET]: {
            transform: 'translate(-100%)',
          },
          [MEDIA_QUERY.DESKTOP]: {
            transform: 'translate(-100%)',
          },
        }
);

type MaskContainerProps = {
  visible: boolean;
};

// TODO: smooth transition while hiding
export const MaskContainer = styled.div(
  {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    transition: 'opacity 0.3s ease-in-out',
    zIndex: 97,
  },
  ({ visible }: MaskContainerProps) => ({
    opacity: visible ? 1 : 0,
    display: visible ? 'block' : 'none',
  })
);

type MaskProps = {
  visible: boolean;
  onClick: () => void;
};

export const Mask: React.FC<MaskProps> = ({ visible, onClick }) => {
  useEffect(() => {
    if (!visible) {
      document.body.classList.remove('disable-scroll');
    } else {
      document.body.classList.add('disable-scroll');
    }
  }, [visible]);

  return <MaskContainer onClick={onClick} visible={visible} />;
};

export const Items = styled.div({
  marginTop: '84px',
  [MEDIA_QUERY.MOBILE]: {
    marginTop: '74px',
    borderTop: `1px solid ${COLORS.GRAY}`,
  },
});

type ItemProps = {
  active?: boolean;
  noHover?: boolean;
};

export const Item = styled.li(
  {
    display: 'block',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${COLORS.GRAY}`,
    transition: 'background-color 0.2s ease-in-out',
  },
  ({ active, noHover }: ItemProps) => {
    const hover = noHover
      ? {}
      : {
          ':hover, :focus': {
            backgroundColor: COLORS.GRAY,
            cursor: 'pointer',
            borderColor: COLORS.GRAY,
          },
        };
    const isActive = active
      ? { backgroundColor: COLORS.GRAY, borderColor: COLORS.GRAY }
      : {};
    return { ...isActive, ...hover };
  }
);

export const List = styled.ul({ margin: 0, padding: 0 });

export const Languages = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  marginTop: '16px',
});

export const Language = styled.div(
  {
    marginLeft: '16px',
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.2s ease-in-out',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.GRAY,
    },
  },
  ({ active }: { active: boolean }) => {
    return active ? { backgroundColor: COLORS.LIGHT_BLUE } : {};
  }
);

export const CategoryList = styled(List)({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
});

const OFFSET = 36;

export const SubcategoryList = styled(List)({
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: 1.43,
  color: COLORS.BLACK,
  display: 'block',
  textTransform: 'uppercase',
  borderBottom: `1px solid ${COLORS.GRAY}`,
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '12px',
  },
});

export const CategoryName = styled.div(
  {
    padding: '16px',
    paddingLeft: `${OFFSET}px`,
    transition: 'background-color 0.4s ease-out',
    cursor: 'pointer',
  },
  ({ isOpen }) => {
    const open = { backgroundColor: COLORS.GRAY };
    const closed = {};

    return isOpen ? open : closed;
  }
);

export const CategoryProducts = styled(List)(
  {
    overflow: 'hidden',
    transition: 'max-height 0.5s ease-out',
    height: 'auto',
  },
  ({ isOpen, length, height = 100 }) => {
    const open = { maxHeight: `${length * height}px` };
    const closed = { maxHeight: '0px' };

    return isOpen ? open : closed;
  }
);

export const CategoryProduct = styled(Item)({
  padding: '16px',
  paddingLeft: `${OFFSET * 1.5}px`,
  fontWeight: 'bold',
  cursor: 'pointer',
  color: COLORS.GRAY,
});

export const SubcategoryProductsList = styled(List)({
  color: COLORS.GREY_V2,
  fontSize: '13px',
});

export const SubcategoryTitle = styled.div(
  {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${COLORS.GRAY}`,
    padding: '16px',
    paddingLeft: `${OFFSET * 1.5}px`,
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
    color: COLORS.GREY_V2,
  },
  ({ active }) => {
    const isActive = active
      ? { backgroundColor: COLORS.GRAY, borderColor: COLORS.GRAY }
      : {};
    return { ...isActive };
  }
);

export const SubcategoryProductContainer = styled.li({
  padding: '16px',
  fontSize: '12px',
  cursor: 'pointer',
  lineHeight: 2,
  letterSpacing: '1px',
  textTransform: 'capitalize',
  paddingLeft: `${OFFSET * 3}px`,
  color: COLORS.GREY_V2,
  borderBottom: `1px solid ${COLORS.GRAY}`,
});

export const Hideable = styled.div(
  {
    overflow: 'hidden',
    transition: 'max-height 0.5s ease-out',
    height: 'auto',
  },
  ({ isOpen, length = 1, height = 100 }) => {
    const open = { maxHeight: `${length * height}px` };
    const closed = { maxHeight: '0px' };

    return isOpen ? open : closed;
  }
);

export const ProductLink = styled.a({
  textDecoration: 'none',
  color: COLORS.GREY_V2,

  ':visited, :focus': {
    color: COLORS.GREY_V2,
  },
});
